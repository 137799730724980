import {
  GET_REQUESTS,
  CREATE_KITS_BULK,
  FINALIZE_REQUEST_APPROVAL,
  RETRIEVE_SHIPPING_PDF,
  RETRIEVE_STORE_SHIPPING_PDF,
  GET_CUSTOMER_ORDERS,
  LOAD_RELATED_KITS,
  GET_KIT,
  GET_KITS,
  GET_ALL_KITS,
  GET_CUST_KITS,
  GET_KIT_ACCESS,
  SAVE_KIT_CUSTOMER,
  SAVE_KIT_BUSINESS,
  SELL_KIT,
  GET_RECS,
  SAVE_REC,
  EDIT_REC,
  GET_PROD_REC,
  GET_REC_INFO,
  TOGGLE_REC_DIALOG,
  TOGGLE_REQUEST_DETAILS_DIALOG,
  GET_RECOMMENDATIONS,
  GROS_LOADING,
  GROS_LOAD_ERROR,
  CLEAR_REQUEST_STATE,
  SAVE_GROWING_AREA,
  EDIT_GROWING_AREA,
  SAVE_CUSTOMER_NOTES,
  AMAZON_VALIDATE,
  AMAZON_GROWING_AREA,
  SAVE_KIT_NOTES,
} from './actions';

const initialState = {
  columns: [],
  kit: [],
  kits: [],
  kitArray: [],
  custKitArray: [],
  rec: [],
  recInfo: [],
  requests: [],
  orders: [],
  requestColumns: [],
  recommendations: [],
  recommendation: [],
  kitsRelatedToRequest: [],
  growingAreas: [],
  growingAreaInfo: [],
  activeRequestState: '',
  pdfData: '',
  recDialogOpen: false,
  requestDetailsDialogOpen: false,
  loading: false,
  loadError: false,
  errorData: null,
  notes: null,
  return: null,
  kitNotes: null,
};

export default function (state = initialState, action) {
  const {
    type,
    columns,
    data,
    requestColumns,
  } = action;
  switch (type) {
    case GROS_LOADING:
      return {
        ...state,
        loading: true,
        loadError: false,
        errorData: null,
      };
    case GROS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
        errorData: data,
      };
    case CREATE_KITS_BULK:
      return {
        ...state,
        kitsRelatedToRequest: data,
      };
    case RETRIEVE_SHIPPING_PDF:
      return {
        ...state,
        pdfData: data,
      };
    case RETRIEVE_STORE_SHIPPING_PDF:
      return {
        ...state,
        pdfData: data,
      };
    case GET_CUSTOMER_ORDERS:
      return {
        ...state,
        orders: data,
      };
    case GET_REQUESTS:
      return {
        ...state,
        requestColumns,
        requests: data,
      };
    case FINALIZE_REQUEST_APPROVAL:
      return {
        ...state,
        activeRequestState: data,
      };
    case CLEAR_REQUEST_STATE:
      return {
        ...state,
        activeRequestState: '',
      };
    case LOAD_RELATED_KITS:
      return {
        ...state,
        kitsRelatedToRequest: data,
      };
    case GET_KIT:
      return {
        ...state,
        kit: data,
      };
    case GET_KITS:
      return {
        ...state,
        columns,
        kits: data,
      };
    case GET_ALL_KITS:
      return {
        ...state,
        columns,
        kitArray: data,
      };
    case GET_CUST_KITS:
      return {
        ...state,
        columns,
        custKitArray: data,
      };
    case GET_KIT_ACCESS:
      return {
        ...state,
        columns,
        kits: data,
      };
    case SAVE_KIT_CUSTOMER:
      return {
        ...state,
      };
    case SAVE_KIT_BUSINESS:
      return {
        ...state,
      };
    case SELL_KIT:
      return {
        ...state,
      };
    case GET_PROD_REC:
      return {
        ...state,
        recommendations: data,
      };
    case GET_RECS:
      return {
        ...state,
        columns,
        recommendations: data,
      };
    case SAVE_REC:
      return {
        ...state,
        ids: data,
      };
    case EDIT_REC:
      return {
        ...state,
        rec: data,
      };
    case TOGGLE_REC_DIALOG:
      return {
        ...state,
        recommendation: data,
        recDialogOpen: !state.recDialogOpen,
      };
    case TOGGLE_REQUEST_DETAILS_DIALOG:
      return {
        ...state,
        // request?: data,
        requestDetailsDialogOpen: !state.requestDetailsDialogOpen,
      };
    case GET_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: data,
      };
    case GET_REC_INFO:
      return {
        ...state,
        recInfo: data,
      };
    case SAVE_GROWING_AREA:
      return {
        ...state,
        growingAreaInfo: data,
      };
    case EDIT_GROWING_AREA:
      return {
        ...state,
        growingAreaInfo: data,
      };
    case SAVE_CUSTOMER_NOTES:
      return {
        ...state,
      };
    case AMAZON_VALIDATE:
      return {
        ...state,
        return: data,
      };
    case AMAZON_GROWING_AREA:
      return {
        ...state,
      };
    case SAVE_KIT_NOTES:
      return {
        ...state,
      };
    default:
      return state;
  }
}
