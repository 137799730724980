// React Redux Core
import React from 'react';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import { useIsAuthenticated, useRefreshToken } from 'react-auth-kit';
import CircularProgress from '@mui/material/CircularProgress';
// Adapify
import { RefreshComponent } from './Utilities/Utilities';
import Orders from './Orders';
// Lazy Imports
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const UserSettings = React.lazy(() => import('./UserSettings/UserSettings'));
const Management = React.lazy(() => import('./Management/Management'));
const LoginRegister = React.lazy(() => import('./LoginRegister/LoginRegister'));
const PasswordReset = React.lazy(() => import('./LoginRegister/PasswordReset'));
const Store = React.lazy(() => import('./Store/Store'));
const Checkout = React.lazy(() => import('./Store/Checkout'));
const KitRouter = React.lazy(() => import('./RxSoil/Kit/KitRouter'));
const RegisterKit = React.lazy(() => import('./RxSoil/Kit/RegisterKit'));
const BlogPage = React.lazy(() => import('./BlogPage'));
const Amazon = React.lazy(() => import('./Store/Amazon'));
const RegistrationLanding = React.lazy(() => import('./Store/Checkout/RegistrationLanding'));

const App = () => {
  const refState = useRefreshToken();
  const isAuth = useIsAuthenticated();
  const access = localStorage.getItem('_auth');
  const currentTime = new Date();
  const expireTime = new Date(refState.getCurrentAuthState().expireAt);
  const expired = expireTime - currentTime;
  let hasAccess = true;
  if (expired < 0) {
    if ((access === null) || (access === undefined)) {
      hasAccess = false;
    } else if (access !== refState.getCurrentAuthState().authToken) {
      hasAccess = false;
    }
    if (!hasAccess) {
      RefreshComponent();
    }
  }
  let loggedIn = false;
  if (isAuth()) {
    loggedIn = true;
  } else if (localStorage.getItem('_auth_refresh') !== null) {
    loggedIn = true;
  } else if (hasAccess) {
    loggedIn = true;
  }

  const PrivateRoute = ({ children }) => (loggedIn ? children : <Navigate replace to="/" />);

  const routes = (
    <Routes>
      <Route path="/kit/:kitId" element={<PrivateRoute><KitRouter /></PrivateRoute>} />
      <Route path="/register" element={<RegisterKit />} />
      <Route path="/complete/:confirmationID" element={<RegistrationLanding />} />
      <Route path="/store" element={<Store />} />
      <Route path="/checkout/:urlProducts" element={<PrivateRoute><Checkout /></PrivateRoute>} loginPath="/checkout/:urlProducts" />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} loginPath="/orders" />
      <Route path="/settings" element={<PrivateRoute><UserSettings /></PrivateRoute>} loginPath="/" />
      <Route path="/management" element={<PrivateRoute><Management /></PrivateRoute>} loginPath="/" />
      <Route path="/store" element={<PrivateRoute><Store /></PrivateRoute>} loginPath="/" />
      <Route path="/amazon" element={<PrivateRoute><Amazon /></PrivateRoute>} loginPath="/" />
      <Route path="/start" element={<PrivateRoute><Amazon /></PrivateRoute>} loginPath="/" />
      <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} loginPath="/" />
    </Routes>
  );

  return (
    <React.Suspense fallback={<center><CircularProgress /></center>}>
      <BrowserRouter>
        {loggedIn
          ? routes
          : (
            <Routes>
              <Route path="/kit/:kitId" element={<KitRouter />} />
              <Route path="/register" element={<LoginRegister />} />
              <Route path="/complete/:confirmationID" element={<RegistrationLanding />} />
              <Route path="/management" element={<PrivateRoute><Management /></PrivateRoute>} loginPath="/" />
              <Route path="/store" element={<Store />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/checkout/:urlProducts" element={<Checkout />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/amazon" element={<Amazon />} />
              <Route path="/start" element={<Amazon />} />
              <Route path="/user/reset" element={<PasswordReset />} />
              <Route path="/user/reset/:resetToken/:timestamp/:key" element={<PasswordReset />} />
              <Route path="/" element={<LoginRegister />} />
            </Routes>
          )}
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
