import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Typography,
  List,
  Button,
  Divider,
  Dialog,
  Collapse,
  IconButton,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { useRefreshToken } from 'react-auth-kit';
import { RefreshComponent } from './Utilities/Utilities';
import Header from './Header/Header';
import OrderSummary from './OrderSummary';
import { getCustomerOrders } from './Management/Store/redux/actions';
import theme from './theme';

const root = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fafafa',
};
const orderSections = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '12px 0px 21px 15%',
  width: '100%',
  [theme.breakpoints.down(978)]: {
    margin: '12px 0px 21px 15%',
  },
  [theme.breakpoints.down(480)]: {
    marginLeft: '2%',
    alignItems: 'center',
  },
};
const card = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 420,
  minHeight: 219,
  width: '68%',
  margin: '8px 10px',
  [theme.breakpoints.down(978)]: {
    minWidth: '90%',
    margin: '6px 10px',
  },
  [theme.breakpoints.down(778)]: {
    minWidth: '94%',
  },
};
const topContainer = {
  padding: '1px 13px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottomStyle: 'solid',
  borderColor: 'lightgray',
  borderWidth: 0,
  justifyContent: 'space-between',
  [theme.breakpoints.down(480)]: {
    flexDirection: 'column',
    justifyContent: 'left',
  },
};
const orderNumberContainer = {
  display: 'flex',
  flexDirection: 'row',
  padding: '6px 0px',
};
const orderNumber = {
  fontWeight: 500,
  paddingLeft: '9px',
  fontSize: 24,
  color: 'lightgrey',
};
const itemsContent = {
  display: 'flex',
  paddingBottom: 0,
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
};
const image = {
  padding: '7px 10px',
  height: 'auto',
  width: '23%',
  objectFit: 'cover',
};
const actionsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '9px 17px',
  paddingLeft: 11,
  width: '50%',
  [theme.breakpoints.down(1271)]: {
    width: '60%',
  },
  [theme.breakpoints.down(978)]: {
    width: '100%',
  },
};
const priceContainer = {
  display: 'flex',
  flexDirection: 'row',
};
const pageTitle = {
  marginLeft: '25%',
  fontSize: '2.3rem',
  marginTop: '16px',
  [theme.breakpoints.down(480)]: {
    marginLeft: '14%',
  },
};
const totalPrices = {
  display: 'flex',
  flexDirection: 'row',
  paddingRight: '2.4%',
  [theme.breakpoints.down(978)]: {
    paddingLeft: 13,
  },
};
const totalPricesTypog = {
  fontSize: 26,
};
const bottomContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(978)]: {
    flexDirection: 'column-reverse',
    alignItems: 'baseline',
  },
};
const expand = {
  transform: 'rotate(0deg)',
  padding: 0,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
};
const expandOpen = {
  transform: 'rotate(180deg)',
};
const dateContainer = {
  marginLeft: '1%',
  fontSize: 22,
  fontWeight: 500,
};
const detailsFont = {
  fontSize: 21,
};
const nameFont = {
  fontSize: 28,
};

const Orders = () => {
  const [detailOpen, setDetailOpen] = useState(false);
  const [allItems, setAllItems] = useState(false);
  const dispatch = useDispatch();
  if (!useRefreshToken().getCurrentAuthState().authToken) { RefreshComponent(); }
  const access = useRefreshToken().getCurrentAuthState().authToken;
  const [selectedOrder, setSelectedOrder] = useState({});
  const custOrders = useSelector((state) => state.mgmtStore.orderArray);
  const adminStatus = false;
  const handleClose = () => {
    setDetailOpen(false);
    setSelectedOrder({});
  };

  const handleViewOrderClick = (thisOrder) => {
    setSelectedOrder(thisOrder);
    setDetailOpen(true);
  };

  const handleExpandClick = () => {
    setAllItems(!allItems);
  };

  const getAllProductIDs = (prodUnits) => {
    const allProductIDs = []; // array for all product_id's
    if (prodUnits) {
      // eslint-disable-next-line array-callback-return
      prodUnits.map((prodUnit) => {
        allProductIDs.push(prodUnit.product_id);
      });
    }

    return allProductIDs;
  };

  const generateExpandedItems = (arr, prodArr) => {
    const expandedItems = [];
    const allProductIDs = getAllProductIDs(arr);
    let name = '';
    let filePath = prodArr[0].file_path;
    if (prodArr[0].display_name.length > 30) {
      name = `${prodArr[0].display_name.substr(0, 28)}...`;
    } else {
      name = prodArr[0].display_name;
    }

    arr.forEach((item, i) => {
      if (i !== 0) {
        if (prodArr[0].id !== item.product_id) {
          // eslint-disable-next-line array-callback-return
          prodArr.map((productArrItem, i) => {
            if (productArrItem.id === item.product_id) {
              filePath = prodArr[i].file_path;
              if (prodArr[i].display_name.length > 30) {
                name = `${prodArr[i].display_name.substr(0, 28)}...`;
              } else {
                name = prodArr[i].display_name;
              }
            }
          });
        }
        const firstOccurenceOfProdID = allProductIDs.indexOf(item.product_id);
        if (i === firstOccurenceOfProdID) {
          expandedItems.push(
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <img style={image} src={`${process.env.REACT_APP_ADAPIFY_SITE_URL}${filePath}`} alt="" key={`orderProductImage-${item.id}`} />
              <div
                style={{
                  display: 'flex', flexDirection: 'column', width: '68%', height: '100%', justifyContent: 'center', marginLeft: 15,
                }}
              >
                <Typography variant="h5">
                  {name}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={priceContainer}>
                    <Typography sx={detailsFont}>
                      Price:
                    </Typography>
                    <Typography sx={detailsFont} style={{ paddingLeft: 7 }}>
                      {item.price}
                    </Typography>
                  </div>
                  <div style={priceContainer}>
                    <Typography sx={detailsFont}>
                      QTY:
                    </Typography>
                    <Typography sx={detailsFont} style={{ paddingLeft: 8 }}>
                      {item.quantity}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>,
          );

          if (i !== arr.length - 1) {
            expandedItems.push(
              <Divider />,
            );
          }
        }
      }
    });

    return expandedItems;
  };

  // check if productUnit.product_type array has different values. If not, keep it at one item
  const generateItemPreview = (arr, prodArr) => {
    const itemPreview = [];
    const allProducts = getAllProductIDs(arr);
    let name = '';
    let multipleItems = false;
    let filePath = prodArr[0].file_path;
    if (prodArr[0].display_name.length > 30) {
      name = `${prodArr[0].display_name.substr(0, 28)}...`;
    } else {
      name = prodArr[0].display_name;
    }

    arr.forEach((item, i) => {
      if (prodArr[0].id !== item.product_id) {
        // eslint-disable-next-line array-callback-return
        prodArr.map((productArrItem, i) => {
          if (productArrItem.id === item.product_id) {
            filePath = prodArr[i].file_path;
            if (prodArr[i].display_name.length > 30) {
              name = `${prodArr[i].display_name.substr(0, 28)}...`;
            } else {
              name = prodArr[i].display_name;
            }
          }
        });
      }
      if (arr.length !== 0 && i === 0) {
        // eslint-disable-next-line array-callback-return
        allProducts.map((productID) => {
          if (item.product_id !== productID) {
            multipleItems = true;
          }
        });
        itemPreview.push(
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img style={image} src={`${process.env.REACT_APP_ADAPIFY_SITE_URL}${filePath}`} alt="" key={`orderProductImage-${item.id}`} />
            <div style={{
              display: 'flex', flexDirection: 'column', width: '68%', height: '100%', justifyContent: 'center', marginLeft: 15,
            }}
            >
              <Typography sx={nameFont}>
                {name}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: 6,
                }}
              >
                <div style={priceContainer}>
                  <Typography sx={detailsFont}>
                    Price:
                  </Typography>
                  <Typography sx={detailsFont} style={{ paddingLeft: 7 }}>
                    {item.price}
                  </Typography>
                </div>
                <div style={priceContainer}>
                  <Typography sx={detailsFont}>
                    QTY:
                  </Typography>
                  <Typography style={{ paddingLeft: 8 }} sx={detailsFont}>
                    {item.quantity}
                  </Typography>
                </div>
              </div>
            </div>
          </div>,
        );

        itemPreview.push(
          <Divider sx={(allItems ? { marginTop: 10 } : { display: 'none' })} />,
        );

        itemPreview.push(
          <div style={((arr.length > 1 && (multipleItems)) ? { display: 'flex', flexDirection: 'column', alignSelf: 'center' } : { display: 'none', flexDirection: 'column', alignSelf: 'center' })}>
            <Collapse in={allItems} timeout="auto" unmountOnExit>
              {generateExpandedItems(arr, prodArr)}
            </Collapse>
            {(allItems ? <Typography style={{ fontSize: 19, fontWeight: 500, textAlign: 'center' }}> See Less </Typography> : <Typography style={{ fontSize: 19, fontWeight: 500, textAlign: 'center' }}> See More </Typography>)}
            <IconButton
              sx={clsx(expand, {
                [expandOpen]: allItems,
              })}
              onClick={handleExpandClick}
              aria-expanded={allItems}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>,
        );
      }
    }); // end of for each loop

    return itemPreview;
  };

  const generateOrderPreview = (orders) => {
    const orderPreview = [];
    // eslint-disable-next-line array-callback-return
    orders.forEach((value) => {
      const order = value;
      let orderStatus = value.status;
      if (value.status.indexOf('confirmed') !== -1) {
        orderStatus = 'Confirmed';
      } else {
        orderStatus = 'Not Confirmed';
      }
      let newDate = 'Date Not Found';
      if (value.transaction.status_date) {
        const theDate = new Date(value.transaction.status_date * 1000).toLocaleDateString('en-US');
        newDate = theDate;
      }
      orderPreview.push(
        <div style={orderSections}>
          <Typography sx={dateContainer}>
            {newDate}
          </Typography>
          <Card sx={card}>
            <div style={topContainer}>
              <div style={orderNumberContainer}>
                <Typography style={{ fontWeight: 500, fontSize: 24, color: 'lightgrey' }}>
                  Order
                </Typography>
                <Typography sx={orderNumber}>
                  {value.invoice_id}
                </Typography>
              </div>
              <Typography
                style={{
                  fontWeight: 500, fontSize: 22, color: '#8cc63e',
                }}
              >
                {orderStatus}
              </Typography>
            </div>
            <List
              style={{
                display: 'flex',
                backgroundColor: '#fff',
                flexDirection: 'column',
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomStyle: 'solid',
                borderTopStyle: 'solid',
                borderColor: 'lightgray',
                borderWidth: 1,
                minHeight: '11vh',
              }}
            >
              {generateItemPreview(value.productUnit, value.product)}
            </List>
            <div style={bottomContainer}>
              <div style={actionsContainer}>
                <Button style={{ width: '48.5%', backgroundColor: '#8cc63e', color: 'white' }} variant="contained" onClick={() => handleViewOrderClick(order)}> View Order </Button>
                <Button style={{ width: '48.5%', color: '#8cc63e' }} variant="outlined"> Re-Order </Button>
              </div>
              <div style={totalPrices}>
                <Typography sx={totalPricesTypog}>
                  Total:
                </Typography>
                <Typography style={{ paddingLeft: 10 }} sx={totalPricesTypog}>
                  {value.price}
                </Typography>
              </div>
            </div>
          </Card>
        </div>,
      );
    });
    return orderPreview;
  };

  useEffect(() => {
    dispatch(getCustomerOrders(access));
  }, []);

  return (
    <>
      <Header hideOnScroll />
      <div style={root}>
        <Typography sx={pageTitle}> Order History </Typography>
        {(!custOrders || custOrders.length === 0) && (
          <>
            <CircularProgress sx={{ alignSelf: 'center', height: '80px' }} />
          </>
        )}
        <List sx={itemsContent}>
          {generateOrderPreview(custOrders)}
        </List>
      </div>
      <Dialog
        open={detailOpen}
        keepMounted
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <OrderSummary order={selectedOrder} adminStatus={adminStatus} />
      </Dialog>
    </>
  );
};

export default Orders;
